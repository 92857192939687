<template>
  <section class="me" :class="{ editor: mode == 'editor' }"></section>
</template>

<script>
export default {
  name: "EquipmentEmptyPanel",
  props: {
    mode: {
      type: String,
      default: "viewer",
      required: false
    }
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
}
.editor {
  background-color: rgba(245, 245, 245, 0.411) !important;
  border: 2px solid rgb(173, 173, 173);
}
</style>
